import React, { useState, useEffect } from 'react';
import '../css/index.css';

const CookieBanner = ({ onAccept }) => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (!localStorage.getItem('cookies-accepted')) {
            setVisible(true);
        }
    }, []);

    const handleAcceptCookies = () => {
        localStorage.setItem('cookies-accepted', 'true');
        setVisible(false);
        onAccept();
    };

    return (
        visible && (
            <div className="cookie-banner">
                <p alt="privacy-policy text">
                    Our website uses cookies to ensure you get the best experience on our website. 
                    By continuing to browse the site, you are agreeing to our use of cookies. 
                    <a alt="privacy-policy link" href="/privacy-policy">Learn more</a>
                </p>
                <button alt="privacy-policy button" onClick={handleAcceptCookies} className="button">Accept</button>
            </div>
        )
    );
};

export default CookieBanner;

