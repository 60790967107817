import React, { useEffect, useState } from 'react';
import 'react-calendar/dist/Calendar.css';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase/firebase';
import AddBookingTwo from '../../components/AddBookingTwo';
import BookingListTwo from '../../components/BookingListTwo';
import CalendarTwo from '../../components/CalendarTwo';

const HorogAdminTwo = () => {
  const [bookingstwo, setBookings] = useState([]);
  
  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'bookingstwo'));
        const fetchedBookings = querySnapshot.docs.map(doc => {
          const data = doc.data();
          const startDate = new Date(data.startDate);
          const endDate = new Date(data.endDate);
          return { startDate, endDate };
        });
        setBookings(fetchedBookings);
      } catch (error) {
        console.error('Error fetching bookings:', error);
      }
    };
  
    fetchBookings();
  }, []);
  
 

  return (
    <div>
      <h1>Két-Horog Pihenőház</h1>
      <CalendarTwo />
      <AddBookingTwo />
      <BookingListTwo bookings={bookingstwo} />
    </div>
  );
};

export default HorogAdminTwo;
