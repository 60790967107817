
import 'firebase/firestore';
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDzrm6KedHxsFUfS2qp60-a25ndHl7ZSt0",
  authDomain: "horog-adfb8.firebaseapp.com",
  projectId: "horog-adfb8",
  storageBucket: "horog-adfb8.appspot.com",
  messagingSenderId: "494601653360",
  appId: "1:494601653360:web:7672f459b2adc56f4278b5",
  measurementId: "G-L2QDRL15B5"
};

// Inicializáljuk a Firebase alkalmazást a konfigurációs adatokkal
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { db, auth , signInWithEmailAndPassword};


