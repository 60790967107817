import React, { useEffect, useState } from 'react';
import { collection, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase/firebase';
import '../css/index.css';

const BookingListTwo = () => {
  const [bookings, setBookings] = useState([]);

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, 'bookingstwo'), (querySnapshot) => {
      const fetchedBookings = querySnapshot.docs.map(doc => {
        const data = doc.data();
        const startDate = new Date(data.startDate);
        const endDate = new Date(data.endDate);
        return { id: doc.id, ...data, startDate, endDate };
      });
      setBookings(fetchedBookings);
    }, (error) => {
      console.error('Error fetching bookings:', error);
    });

    return () => unsubscribe(); // Cleanup subscription on unmount
  }, []);

  const sortByStartDate = (a, b) => {
    return b.startDate - a.startDate; // Sort in descending order
  };

  return (
    <div className="booking-list-container">
      <h3>Booking List</h3>
      <div className="booking-list-scroll">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Guests</th>
              <th>Start Date</th>
              <th>End Date</th>
            </tr>
          </thead>
          <tbody>
            {bookings.sort(sortByStartDate).map((booking) => (
              <tr key={booking.id}>
                <td>{booking.name}</td>
                <td>{booking.email}</td>
                <td>{booking.guests}</td>
                <td>{booking.startDate.toLocaleDateString()}</td>
                <td>{booking.endDate.toLocaleDateString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BookingListTwo;