import React from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

// Képek importálása
import Picture1 from '../image/2/1.webp';
import Picture2 from '../image/2/2.webp';
import Picture3 from '../image/2/3.webp';
import Picture4 from '../image/2/4.webp';

const ImageGalleryComponentTwo = () => {
  const images = [
    { original: Picture1, thumbnail: Picture1, alt: 'Horog Pihenőház Kert 1', width: 1600, height: 800 },
    { original: Picture2, thumbnail: Picture2, alt: 'Horog Pihenőház Kert 2', width: 1600, height: 800 },
    { original: Picture3, thumbnail: Picture3, alt: 'Horog Pihenőház Kert 3', width: 1600, height: 800 },
    { original: Picture4, thumbnail: Picture4, alt: 'Horog Pihenőház Hálószoba 1', width: 1600, height: 800 },
  ];

  // Egyedi renderelő funkció a képekhez
  const renderImage = (item) => (
    <picture>
      <source srcSet={item.original} type="image/webp" />
      <img
        src={item.original}
        alt={item.alt}
        loading="lazy"
        style={{
          width: '100%',
          maxWidth: '1500px',
          height: 'auto',
          borderRadius: '10px',
          boxShadow: '0 0 25px rgba(0, 0, 0, 1)',
        }}
      />
    </picture>
  );

  // Egyedi renderelő funkció a miniatűrökhöz
  const renderThumbnail = (item) => (
    <img
      src={item.thumbnail}
      alt={item.alt}
      loading="lazy"
      style={{
        width: '100%',
        height: 'auto',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
        borderRadius: '5px',
      }}
    />
  );

  return (
    <ImageGallery
    items={images}
    renderItem={renderImage}
    renderThumbInner={renderThumbnail}
    showThumbnails={true}
    showPlayButton={false} // Az autoplay gomb eltávolítása a felesleges renderelés elkerülése érdekében
    showFullscreenButton={true} // Az fullscreen gomb eltávolítása a felesleges renderelés elkerülése érdekében
  />
);
};

export default ImageGalleryComponentTwo;

