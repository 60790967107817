// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import HomePage from '../src/pages/HomePage';
import AdminPage from '../src/pages/AdminPage';
import LoginPage from '../src/pages/LoginPage';
import ProtectedRoute from '../src/components/ProtectedRoutes';
import { BookingsProvider } from './contexts/BookingsContext';
import Rules from '../src/pages/singlepages/Rules';
import Directions from '../src/pages/singlepages/Directions';
import Navigation from './components/Navigation';
import CookieBanner from './components/CookieBanner'; // Import your CookieBanner component
import Contacts from './pages/singlepages/Contacts';


const App = () => {
  return (
    <BookingsProvider>
      <Router>
        <Navigation />
        <CookieBanner /> {/* Add the CookieBanner component here */}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/rules" element={<Rules />} />
          <Route path="/directions" element={<Directions />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/admin" element={
            <ProtectedRoute>
              <AdminPage />
            </ProtectedRoute>
          } />
        </Routes>
        
        
      </Router>
    </BookingsProvider>
  );
};

export default App;




