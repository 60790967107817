// singlepages/Directions.js
import React from 'react';
import MapComponent from '../../components/MapComponent';

const Directions = () => {
  return (
    <div className='home py-3' >
        <div className='container py-5' >
        <div className='dekor'><br></br></div>
            <br></br>
            <h2 className='bg-white p-3'>Megközelítés</h2>
            <br></br>
            <h4>Az aloldalon található egy beágyazott Google Maps térkép, amely segít Önnek üdülőnk egyszerű megtalálásában. Írja be az indulási helyét, és a térkép útvonaltervet készít Önnek!
            </h4>
            <br></br>
            <MapComponent />
            <br></br>
        </div>
    </div>
  );
};

export default Directions;
