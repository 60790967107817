import React, { useState } from 'react';
import { db } from '../firebase/firebase'; // Importáld a Firestore példányt
import { collection, addDoc } from 'firebase/firestore';
import 'react-calendar/dist/Calendar.css'; // Stílusok importálása
import '../css/AdminPage.css'; // A saját stílusok importálása

const AddBookingTwo = () => {
  const [form, setForm] = useState({ // Állapot az űrlap mezőinek tárolására

    guests: '',
    name: '',
    email: '',
    startDate: '',
    endDate: ''
  });

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleAddBooking = async (e) => {
    e.preventDefault();
    if ( !form.guests || !form.name || !form.email || !form.startDate || !form.endDate) {
      alert('Please fill in all fields.');
      return;
    }

    try {
      await addDoc(collection(db, "bookingstwo"), {
        ...form,
        guests: Number(form.guests),
        startDate: new Date(form.startDate).toISOString(),
        endDate: new Date(form.endDate).toISOString()
      });
    } catch (error) {
      console.error("Error adding booking: ", error);
      alert('Failed to add booking.');
    
  

    }
  };

  return (
    <div>
      <h2>Foglalás Hozzáadás:</h2>
      <form onSubmit={handleAddBooking}>
        <input name="guests" value={form.guests} onChange={handleFormChange} placeholder="Number of Guests" type="number" />
        <input name="name" value={form.name} onChange={handleFormChange} placeholder="Name" />
        <input name="email" value={form.email} onChange={handleFormChange} placeholder="Email" />
        <input name="startDate" value={form.startDate} onChange={handleFormChange} placeholder="Start Date" type="date" />
        <input name="endDate" value={form.endDate} onChange={handleFormChange} placeholder="End Date" type="date" />
        <button type="submit">Add Booking</button>
      </form>
    </div>
  );
};

export default AddBookingTwo;