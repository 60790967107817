import React, { useEffect, useState } from 'react';
import 'react-calendar/dist/Calendar.css';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase/firebase';
import BookingList from '../../components/BookingList';

import AddBooking from '../../components/AddBooking';
import '../../css/index.css';
import CalendarOne from '../../components/CalendarOne';
const HorogAdminOne = () => {
  const [bookings, setBookings] = useState([]);
  
  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'bookings'));
        const fetchedBookings = querySnapshot.docs.map(doc => {
          const data = doc.data();
          const startDate = new Date(data.startDate);
          const endDate = new Date(data.endDate);
          return { startDate, endDate };
        });
        setBookings(fetchedBookings);
      } catch (error) {
        console.error('Error fetching bookings:', error);
      }
    };
  
    fetchBookings();
  }, []);
  


  return (
    <div>
      <h1>Horog Pihenőház</h1>
      <CalendarOne />
      <AddBooking />
      <BookingList bookings={bookings} />
    </div>
  );
};

export default HorogAdminOne;
