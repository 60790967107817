// components/Navigation.js
import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import '../../src/css/index.css';

const Navigation = () => {
  const location = useLocation();

  return (
    <Navbar className="navbar-custom align-items-center" expand="lg">
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mx-auto ">
          <Nav.Link
            as={Link}
            to="/"
            className={`nav-link-custom ${location.pathname === '/' ? 'active' : ''}`}
          >
            Főoldal
          </Nav.Link>
          <Nav.Link
            as={Link}
            to="/rules"
            className={`nav-link-custom ${location.pathname === '/rules' ? 'active' : ''}`}
          >
            Szabályzat
          </Nav.Link>
          <Nav.Link
            as={Link}
            to="/directions"
            className={`nav-link-custom ${location.pathname === '/directions' ? 'active' : ''}`}
          >
            Megközelítés
          </Nav.Link>
          <Nav.Link
            as={Link}
            to="/contacts"
            className={`nav-link-custom ${location.pathname === '/contacts' ? 'active' : ''}`}
          >
            Kapcsolat
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Navigation;



