import React, { useState } from 'react';
import HorogAdminOne from './adminpages/HorogAdminOne';
import HorogAdminTwo from './adminpages/HorogAdminTwo';
import '../css/index.css';

const AdminPage = () => {
  const [activePage, setActivePage] = useState('one'); // Az aktív oldal állapotának inicializálása

  // Függvény az oldal váltásához
  const handlePageChange = (page) => {
    setActivePage(page);
  };

  return (
    <div className='container'>
      <h1>Admin Dashboard</h1>
      {/* Gombok az oldalak közötti váltáshoz */}
      <button className={activePage === 'one' ? 'active-button' : 'inactive-button'} onClick={() => handlePageChange('one')}>Horog Pihenőház</button>
      <button className={activePage === 'two' ? 'active-button' : 'inactive-button'} onClick={() => handlePageChange('two')}>Két-Horog Pihenőház</button>
      
      {/* Az aktív oldal megjelenítése */}
      {activePage === 'one' ? <HorogAdminOne /> : <HorogAdminTwo />}
    </div>
  );
};

export default AdminPage;
















