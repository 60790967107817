// singlepages/Rules.js
import React from 'react';

const Rules = () => {
  return (
    <div className='home py-3' alt="Horog Pihenőház  , Horog Piehnőház Mezőtúr, Horog Pihenohaz, Horog Pihenohaz Mezotur">
      
        <div className='container py-5' alt="Horog Pihenőház  , Horog Piehnőház Mezőtúr, Horog Pihenohaz, Horog Pihenohaz Mezotur">
        <div className='dekor'><br></br></div>
        <br></br>
      <h2 className='bg-white p-3' alt="Horog Pihenőház  , Horog Piehnőház Mezőtúr, Horog Pihenohaz, Horog Pihenohaz Mezotur">Szabályzat</h2>
      <br></br>
      <h4>Kérjük, hogy alaposan olvassa el és tartsa be a szabályokat a kellemes és gondtalan pihenés érdekében!</h4>
      <br></br>
      <ol>
        <h3>
        <li> Foglalási feltételek</li>
        <ul><li>Minimum foglalás: 5 éjszaka</li></ul>
        <ul><li>A foglalás csak az előleg befizetésével válik véglegessé!</li></ul>
        <br></br>
        <li> Lemondási feltételek</li>
        <ul><li>Lemondás esetén az előleg nem visszatérítendő.</li></ul>
        <ul><li>14 nappal az érkezés előtt történő lemondás esetén a teljes összeget ki kell fizetni.</li></ul>
        <br></br>
        <li> Házirend</li>
        <ul><li>A házban tilos a dohányzás!</li></ul>
        <ul><li>Háziállatot nem fogadunk!</li></ul>
        <ul><li>Csendrendelet: 22:00-07:00 között kérjük, tartózkodjon a hangoskodástól!</li></ul>
        <ul><li>Lánybúcsút, legénybúcsút nem vállalunk!</li></ul>
        <br></br>
        <li>  Biztonsági előírások</li>
        <ul><li>Kérjük, ügyeljen a tűzhelyek, grillezők és egyéb berendezések használatára!</li></ul>
        <ul><li>A medence használata saját felelősségre történik, gyerekek csak felnőtt felügyelete mellett használhatják!</li></ul>
        <br></br>
        </h3>
      </ol>
      <div className='dekor'><br></br></div>
      <br></br>
      </div>
    </div>
  );
};

export default Rules;
