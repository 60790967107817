import React, { useState, useEffect } from 'react';
import Horog from './singlepages/Horog';
import KetHorog from './singlepages/KetHorog';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/index.css';
import logo22 from '../image/logo22.png'; // Kép importálása

const HomePage = () => {
  const [activePage, setActivePage] = useState('one');

  // SEO szempontú beállítások
  useEffect(() => {
    document.title = 'Horog-Pihenőházak - Családbarát Horgász Nyaralók Mezőtúron';
    
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute("content", "Üdvözöljük a Horog-Pihenőházak weboldalán! Válasszon családbarát nyaralót Mezőtúron, a természet és a horgászat szerelmeseinek.");
    } else {
      const meta = document.createElement('meta');
      meta.name = "description";
      meta.content = "Üdvözöljük a Horog-Pihenőházak weboldalán! Válasszon családbarát nyaralót Mezőtúron, a természet és a horgászat szerelmeseinek.";
      document.getElementsByTagName('head')[0].appendChild(meta);
    }

    const metaKeywords = document.querySelector('meta[name="keywords"]');
    if (metaKeywords) {
      metaKeywords.setAttribute("content", "Mezőtúr, nyaraló, pihenőház, horgászat, családbarát nyaralás, természet, vízparti nyaralás");
    } else {
      const meta = document.createElement('meta');
      meta.name = "keywords";
      meta.content = "Mezőtúr, nyaraló, pihenőház, horgászat, családbarát nyaralás, természet, vízparti nyaralás";
      document.getElementsByTagName('head')[0].appendChild(meta);
    }

    const canonical = document.createElement('link');
    canonical.rel = 'canonical';
    canonical.href = window.location.href;
    document.head.appendChild(canonical);

  }, []);

  const handlePageChange = (page) => {
    setActivePage(page);
  };

  return (
    <div className='home '>      
        
        
        {/* Kép elhelyezése a gombok fölött */}
        <div className='text-center'>
          
        </div>

        <div className='button-row text-center'>
        <h1 className='text-center'>Üdvözöljük a Horog-Pihenőházak weboldalán!</h1>
        <h2 className='text-center'>Kérjük válasszon nyaralót :</h2>

          <button
            className={activePage === 'one' ? 'button active-button' : 'button inactive-button'}
            onClick={() => handlePageChange('one')}
          >
            Horog Pihenőház
          </button>
          <img src={logo22} alt="Horog Pihenőházak Logo" className="" />
          <button
            className={activePage === 'two' ? 'button active-button' : 'button inactive-button'}
            onClick={() => handlePageChange('two')}
          >
            Két-Horog Pihenőház
          </button>
        </div>
        
        {activePage === 'one' ? <Horog /> : <KetHorog />}    
    </div>
  );
};

export default HomePage;


