import React, { useState, useEffect } from 'react';
import CookieBanner from './CookieBanner'; // A helyes útvonalat használd
import '../../src/css/index.css';

const MapComponent = () => {
  const [hasConsent, setHasConsent] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('cookies-accepted')) {
      setHasConsent(true);
    }
  }, []);

  const handleAcceptCookies = () => {
    setHasConsent(true);
  };

  return (
    <div className='map-container'>
      <CookieBanner onAccept={handleAcceptCookies} />
      
      {hasConsent ? (
        <iframe
          title="Horog Pihenőház Google Térkép"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1771.463051019113!2d20.6778273!3d46.9539404!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4746a55d63023563%3A0x3e4927dc027d3da5!2sHorog%20pihen%C5%91h%C3%A1z!5e0!3m2!1sen!2shu!4v1626064811735!5m2!1sen!2shu"
          width="100%"
          height="450"
          style={{ border: 0, padding: 10 ,borderRadius: '10px',  height: 500, fontWeight: 'bold' }}
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      ) : (
        <div>
          <p>A térkép megjelenítéséhez engedélyezze a sütik használatát.</p>
        </div>
      )}
      <div><br /></div>
    </div>
    
  );
};

export default MapComponent;

