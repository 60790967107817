import React, { useEffect } from 'react';
import ImageGalleryComponentTwo from '../../components/ImageGalleryComponentTwo';
import '../../css/index.css';
/* import EmailTwo from '../../components/EmailTwo'; */
/* import CalendarTwo from '../../components/CalendarTwo'; */
import InformationContainerTwo from '../../components/InformationContainerTwo';
const updateMetaTags = (title, description, keywords) => {
  document.title = title;

  let metaDescription = document.querySelector('meta[name="description"]');
  if (metaDescription) {
    metaDescription.setAttribute("content", description);
  } else {
    metaDescription = document.createElement('meta');
    metaDescription.name = "description";
    metaDescription.content = description;
    document.head.appendChild(metaDescription);
  }

  let metaKeywords = document.querySelector('meta[name="keywords"]');
  if (metaKeywords) {
    metaKeywords.setAttribute("content", keywords);
  } else {
    metaKeywords = document.createElement('meta');
    metaKeywords.name = "keywords";
    metaKeywords.content = keywords;
    document.head.appendChild(metaKeywords);
  }
};
const KetHorog = () => {
  useEffect(() => {
    updateMetaTags(
      'Két-Horog Pihenőház',
      'Családbarát Horgász Nyaraló Mezőtúron',
      'Horog, Két Horog ,Pihenőház, Mezőtúr, horgász nyaraló, vízparti ház, családbarát pihenés, természet közeli nyaralás'
    );
  }, []);

  return (
    <div >
      <div className='container dekor'><br></br></div>
      <h1 className='cim p-3'>Két-Horog Pihenőház</h1>
      <h2 className='cim' >Mezőtúr, Horog utca 117, 5400</h2>
      <ImageGalleryComponentTwo />
      <div className="container section-row">
       
        {/*
        <div className='dekor'><br></br></div>
         <CalendarTwo /> 
        <EmailTwo />
        */}
        <InformationContainerTwo />
        
      </div>
    </div>
  );
};

export default KetHorog;
