import React from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

// Képek importálása
import Picture1 from '../image/1/1.webp';
import Picture2 from '../image/1/2.webp';
import Picture3 from '../image/1/3.webp';
import Picture4 from '../image/1/4.webp';
import Picture5 from '../image/1/5.webp';
import Picture6 from '../image/1/6.webp';
import Picture7 from '../image/1/7.webp';
import Picture8 from '../image/1/8.webp';
import Picture9 from '../image/1/9.webp';
import Picture11 from '../image/1/11.webp';
import Picture12 from '../image/1/12.webp';

const ImageGalleryComponentOne = () => {
  const images = [
    { original: Picture1, thumbnail: Picture1, alt: 'Horog Pihenőház Terasz', width: 1600, height: 800 },
    { original: Picture2, thumbnail: Picture2, alt: 'Horog Pihenőház Nappali', width: 1600, height: 800 },
    { original: Picture3, thumbnail: Picture3, alt: 'Horog Pihenőház Hálószoba 1', width: 1600, height: 800 },
    { original: Picture4, thumbnail: Picture4, alt: 'Horog Pihenőház Terasz', width: 1600, height: 800 },
    { original: Picture5, thumbnail: Picture5, alt: 'Horog Pihenőház Hálószoba 2', width: 1600, height: 800 },
    { original: Picture6, thumbnail: Picture6, alt: 'Horog Pihenőház Hálószoba 3', width: 1600, height: 800 },
    { original: Picture7, thumbnail: Picture7, alt: 'Horog Pihenőház Folyosó', width: 1600, height: 800 },
    { original: Picture8, thumbnail: Picture8, alt: 'Horog Pihenőház Kert 1', width: 1600, height: 800 },
    { original: Picture9, thumbnail: Picture9, alt: 'Horog Pihenőház Játszótér', width: 1600, height: 800 },
    { original: Picture11, thumbnail: Picture11, alt: 'Horog Pihenőház Medence', width: 1600, height: 800 },
    { original: Picture12, thumbnail: Picture12, alt: 'Horog Pihenőház Kert 2', width: 1600, height: 800 },
  ];

  // Responsive képek kezelése
  const renderImage = (item) => (
    <picture>
      <source srcSet={item.original} type="image/webp" />
      <img
        src={item.original}
        alt={item.alt}
        loading="lazy"
        style={{
          width: '100%',
          maxWidth: '1500px',
          height: 'auto',
          borderRadius: '10px',
          boxShadow: '0 0 25px rgba(0, 0, 0, 1)',
        }}
      />
    </picture>
  );

  // Miniatűr képek optimalizálása
  const renderThumbnail = (item) => (
    <img
      src={item.thumbnail}
      alt={item.alt}
      loading="lazy"
      style={{
        width: '100%',
        height: 'auto',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
        borderRadius: '5px',
      }}
    />
  );

  return (
    <div>
    <ImageGallery
      items={images}
      renderItem={renderImage}
      renderThumbInner={renderThumbnail}
      showThumbnails={true}
      showPlayButton={false} // Az autoplay gomb eltávolítása a felesleges renderelés elkerülése érdekében
      showFullscreenButton={true} // Az fullscreen gomb eltávolítása a felesleges renderelés elkerülése érdekében
    />
    </div>
  );
};

export default ImageGalleryComponentOne;


