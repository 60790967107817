import React from 'react';
import '../../src/css/index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import contactImageOne from '../image/1/1.webp'; // Kép importálása
import contactImageTwo from '../image/1/2.webp'; // Kép importálása

const InformationContainerTwo = () => {


  return (
    <div className="container email-container">
      <div className='row p-3'>
        <div className='dekor'><br></br></div>
        <div className='col-12'>
          <h2>Csendes kikapcsolódás, távol a város zajától</h2>
          <h3 className='p-4'>2021 június 1-től megnyitottuk újonnan épült pihenőházunkat! Foglalás Minimum 5 éjszakára lehetséges.A házban a vízellátás fúrt    kútról történik ,ezért a víz fogyasztásra nem alkalmas, mosogatásra, fürdésre, mosásra igen. Megközelítés: a bekötő út jelenleg felújítás alatt lévő beton út,valamint egy kb 1 km-es lassan járható földút,de a nyaraló bármilyen személyautóval megközelíthető! 
          </h3>
          <h2>Nyugalom és kényelem a természet ölelésében </h2>
          <h3 className='p-4'>A két nyaraló egymás mellett helyezkedik el, és a parton, valamint fent is van átjáró, ami különösen praktikus, ha a házakat egy társaság használja. A tó, amely Magyarország leghosszabb holtága, tele van halakkal, így a horgászok számára is nagyszerű helyszín. Ideális választás, ha a csendes kikapcsolódást részesíti előnyben a bulizás helyett.</h3>
        </div>
        <div className='dekor'><br></br></div>
      </div>
      <div className='row' >
        <div className='col-md-6 p-3' >
          <h3 className='p-4'>
            <ul className='justify-content-start'>        
              <li>5 hálószoba (1 db 3 ágyas, 4 db 2 ágyas)</li>
              <li>Teljes felszereltség: konyhai eszközök, háztartási gépek, hűtő-fűtő klíma minden szobában, 6 db TV, műholdas adás, ingyenes Wifi</li>
              <li>Ágynemű, törölköző, babaágy, etetőszék, kiskád biztosított</li>
              <li>Terasz (32 nm) felnyitható ponyvával védett, étkezővel, jakuzzival</li>
              <li>Nyitott nyári konyha, wc, zuhanyzó</li>
              <li>A kert füvesített, kerítéssel ellátott</li>
              <li>Játszótér (hinta, homokozó), 3,6 méteres medence, napozóágyak, hintaágy, ping-pong asztal, 4 méteres trambulin hálóval, kerti sütögető, bográcsozó tűzifával, 15 nm-es részben fedett horgászstég</li>
              <li>Zárt parkoló 6-8 autó részére</li>
              <li>Csónak biztosítása kérésre</li>
              <li>Ár:85.000 Ft / éj + IFA (11 főre , 2 éves korig ingyenes babaágyban) - 2024 es árak</li>
              <li>Érdeklődjön telefonon: </li>
              <li className='p-4'>+36 20 328 3128</li>         
            </ul>
          </h3>
        </div>
        <div className='col-md-6 p-3' >
        <img src={contactImageOne} alt="Kapcsolat" className="img-fluid mt-4" /> {/* Kép megjelenítése */}
        <img src={contactImageTwo} alt="Kapcsolat" className="img-fluid mt-4" /> {/* Kép megjelenítése */}
        </div>
      </div>
    </div>
  );
};

export default InformationContainerTwo;