import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import '../../src/css/index.css';
// Import the Hungarian locale from moment
import 'moment/locale/hu';

import { collection, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase/firebase';

// Set the moment locale to Hungarian
moment.locale('hu');

const localizer = momentLocalizer(moment);

const CustomToolbar = (toolbar) => {
  return (
    <div className="rbc-toolbar" role="toolbar">
      <span className="rbc-btn-group" role="group" aria-label="Navigation controls">
        <button 
          type="button" 
          onClick={() => toolbar.onNavigate('PREV')}
          aria-label="Előző hónap"
          className="rbc-button-link"
        >
          Előző
        </button>
        <button 
          type="button" 
          onClick={() => toolbar.onNavigate('TODAY')}
          aria-label="Mai nap"
          className="rbc-button-link"
        >
          Ma
        </button>
        <button 
          type="button" 
          onClick={() => toolbar.onNavigate('NEXT')}
          aria-label="Következő hónap"
          className="rbc-button-link"
        >
          Következő
        </button>
      </span>
      <span className="rbc-toolbar-label" role="heading" aria-level="2">
        {toolbar.label}
      </span>
    </div>
  );
};

const CalendarTwo = () => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const bookingsRef = collection(db, 'bookingstwo');
        const unsubscribe = onSnapshot(bookingsRef, (querySnapshot) => {
          const fetchedEvents = querySnapshot.docs.map(doc => {
            const data = doc.data();
            return {
              title: 'Foglalás',
              start: new Date(data.startDate),
              end: new Date(new Date(data.endDate).setDate(new Date(data.endDate).getDate() + 1)),
            };
          });
          setEvents(fetchedEvents);
        });
        return () => unsubscribe();
      } catch (error) {
        console.error('Error fetching bookings:', error);
      }
    };

    fetchBookings();
  }, []);

  const dayPropGetter = (date) => {
    return {
      style: {
        backgroundColor: '#f4f4f4',
        border: '1px solid #ccc',
        width: '100%',
      }
    };
  };

  return (
    <div className="calendar-container">
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500, fontWeight: 'bold', padding: '10px', boxShadow: '0 0 20px rgba(0, 0, 0, 1)', borderRadius: '10px' }}
        components={{ toolbar: CustomToolbar }}
        eventPropGetter={(event) => ({
          style: {
            backgroundColor: '#f00',
            color: 'black',
            fontWeight: 'bold',
            padding: '3px',
          }
        })}
        dayPropGetter={dayPropGetter}
      />
      <div className='p-3'>
        <h3>A
          <span className='bg-red'>
            Piros
          </span>
          <p>színnel jelzett dátumok már foglalt időpontok.</p>
        </h3>
      </div>
    </div>
  );
};

export default CalendarTwo;
