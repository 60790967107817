import React from 'react';
import contactImage from '../../image/1/1.webp'; // Kép importálása

const Contacts = () => {
  return (
    <div className='home py-3' >
      <div className='container py-5 text-center' >
        <div className='dekor'><br></br></div>
          <br></br>
          <h2 className='p-3'>
            Telefonszám:
              <span className='text-white p-2'>
                +36 20 20 328 3128
              </span>
          </h2>
          <h2 className='p-3'>
            E-mail:
              <span className='text-white p-2'>
                foglalas@horogpihenohaz.hu
              </span>
          </h2>
          <img src={contactImage} alt="Kapcsolat" className="img-fluid mt-4" /> {/* Kép megjelenítése */}
      </div>
    </div>
  );
};

export default Contacts;
