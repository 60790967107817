import React from 'react';
import '../../src/css/index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import contactImageOne from '../image/1/3.webp'; // Kép importálása
import contactImageTwo from '../image/1/4.webp'; // Kép importálása
import '../css/index.css'; // Link to your CSS file

const InformationContainerOne = () => {
  return (
    <div className="container email-container">
      <div className='row p-3'>
        <div className='dekor'><br></br></div>
        <div className='col-12 p-3'>
        <h2 className='cím '>Horog ház 5 szoba / 12 fő , Két-Horog 5 szoba / 11 fő , Légkondícionáló , Jakuzzi , Medence, Saját stég, Játszótér, Teljes felszereltség </h2>
          <h3 className='p-4'>A Horogházak nyaralói Mezőtúron, a Peresi-holtág közvetlen vízpartján találhatók, ahol Ön nyugodtan pihenhet, távol a város zajától. Mindkét ház saját stéggel rendelkezik, ami kiváló lehetőség a horgászat szerelmeseinek. A modern berendezések és a teljes felszereltség garantálják a kényelmet és a nyugalmat. A házak jakuzzival, medencével, játszótérrel és számos egyéb szórakozási lehetőséggel várják a vendégeket.</h3>
          <h2>Nyugalom és kényelem a természet ölelésében </h2>
          <h3 className='p-4'>A két nyaraló egymás mellett helyezkedik el, és a parton, valamint fent is van átjáró, ami különösen praktikus, ha a házakat egy társaság használja. A tó, amely Magyarország leghosszabb holtága, tele van halakkal, így a horgászok számára is nagyszerű helyszín. Ideális választás, ha a csendes kikapcsolódást részesíti előnyben a bulizás helyett.</h3>
        </div>
      <div className='dekor'><br></br></div>
    </div>
      <div className='row'>
        <div className='col-md-6 p-3'>
          <h2>Csendes kikapcsolódás, távol a város zajától</h2>
          <h3 className='p-4'> 
            <ul className='justify-content-center'>
              <li>3 db 2 ágyas szoba és 2 db 3 ágyas szoba</li>
              <li>Teljes felszereltség: konyhai eszközök, háztartási gépek, hűtő-fűtő klíma minden szobában, 6 db TV, műholdas adás, internet</li>
              <li>Ágynemű, törölköző, babaágy, etetőszék, kiskád biztosított</li>
              <li>Terasz (32 nm) ülőgarnitúrával, asztallal, székekkel</li>
              <li>Nyári konyhapult, étkező, fedett jakuzzi, zuhanyzó, wc a teraszon kívül</li>
              <li>Gyerekjátszótér, 3,6 méteres medence, napozóágyak, hintaágy, ping-pong asztal</li>
              <li>Parti rész kerítéssel elkerítve, bográcsozó tűzifával, 4m-es trambulin, 12x6 m-es röplabda pálya, részben fedett stég</li>
              <li>Zárt parkoló a ház mögött</li>
              <li>Ár:90.000 Ft / éj + IFA (12 főre , 2 éves korig ingyenes babaágyban) - 2024 - es árak</li>
              <li>Érdeklődjön telefonon: </li>
              <li className='p-4'>+36 20 328 3128</li>
              
            </ul>
          </h3>
        </div>
        <div className='col-md-6 p-3 align-self-center'>
        <img src={contactImageOne} alt="Kapcsolat" className="img-fluid mt-4" /> {/* Kép megjelenítése */}
        <img src={contactImageTwo} alt="Kapcsolat" className="img-fluid mt-4" /> {/* Kép megjelenítése */}
        </div>
        <div className='dekor'><br></br></div>
        <div  className='col-md-12 p-3'>
          <h2>Foglalás menete</h2>
          <h3 className='p-4'>
          <ul>
            <li>A telefonos egyeztetést követően (kiválasztott Horogház, időpont, létszám), kérjük, utalja át az előleget vagy a teljes összeget banki átutalással!</li>
            <li>Az előleg megérkezése után rövid időn belül telefonos visszajelzést adunk a foglalás érvényességéről.</li>
          </ul>
          </h3>
          <div className='dekor'><br></br></div>
          <br></br>
          <h2>Leírás</h2>
          <h3 className='p-4'>
          <ul>           
            <li><h3>Minimum foglalás:</h3>5 éjszaka</li>
            <li><h3>Vízellátás:</h3>Fúrt kútról, fogyasztásra nem alkalmas.</li>
            <li><h3>Megközelítés:</h3>Személyautóval is megközelíthető, 1,2 km-es lassan járható földúton.</li>       
          </ul>
          </h3>
          <div className='dekor'><br></br></div>
        </div>
        <div className='py-3'><h2>Elérhető kényelem, minden évszakban</h2>
        </div>
    </div>
</div>
  );
};

export default InformationContainerOne;
